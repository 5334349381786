import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAPI } from '../api/api';
import { setToken } from '../redux/connection/token';
import { ErrorPage } from '../errorPage/ErrorPage';
import { CreateAccount } from '../createAccount/CreateAccount';
import { ChangePasswordByMail } from '../changePassword/changePassword';
import { InvalidLink } from '../invalidLink/InvalidLink';

export const Redirection = (props) => {

    const connexion = useSelector((state) => state.token)
    const dispatch = useDispatch()


    useEffect(() => {
        if (props.id !== null) {
            getAPI("/idvalidation/" + props.id, "").then(data => {
                if (data.valid) {
                    data.token = props.id
                    data.valid = true
                    dispatch(setToken(data))
                } else {
                    dispatch(setToken({ token: "", valid: false }))
                }
            })
        }
    }, [])

    return (
        <div style = {{display:'flex'}}>
            {connexion.valid &&
                <CreateAccount
                />
            }
            {!connexion.valid && connexion.valid !== undefined && 
                <InvalidLink goto = {props.goto}/>
            }
        </div>

    )
}

export const RedirectionPassword = (props) => {

    const connexion = useSelector((state) => state.token)
    const dispatch = useDispatch()

 

    useEffect(() => {
        if (props.change !== null) {
            getAPI("/checkresettoken/" + props.change, "").then(data => {
                if (data.valid) {
                    data.token = props.change
                    data.valid = true
                    dispatch(setToken(data))
                } else {
                    dispatch(setToken({ token: "", valid: false }))
                }
            })
        }
    }, [])

    return (
        <div style = {{display : 'flex', margin : 'auto'}}>
            {connexion.valid &&
                <ChangePasswordByMail />
            }
            {!connexion.valid &&  connexion.valid !== undefined && 
                <InvalidLink goto = {props.goto}/>
            }
        </div>

    )
}