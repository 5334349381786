import React, { useState } from 'react';
import { TitleFont20WithoutMarginBlueSso, UnderTextSso, IconOnaos } from 'onaosreact'
import { internalDiv } from '../styles/internalDiv';
import { useNavigate } from 'react-router-dom';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Ring } from 'react-awesome-spinners'
import { useEffect } from 'react';

export const CreateAccountSuccess = (props) => {

    const navigate = useNavigate();
    const [first, setfirst] = useState(false);
    const [second, setSecond] = useState(false);

    const divAlign = {
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: "10px",
        alignItems: 'center',
        height: '250px',
        justifyContent: 'space-evenly',
    }

    const circleStyle = {
        display: "flex",
        justifyContent: "space-evenly",
        width: " 170px",
        color: "green"
    }



    useEffect(() => {
        const printFirst = setTimeout(() => {
            setfirst(true)
        }, 1000);
        return () => clearTimeout(printFirst)
    }, [])

    useEffect(() => {
        const printSecond = setTimeout(() => {
            setSecond(true)
        }, 2000);
        return () => clearTimeout(printSecond)
    }, [])

    useEffect(() => {
        const printSecond = setTimeout(() => {
            navigate("/connexion")
        }, 3000);
        return () => clearTimeout(printSecond)
    }, [])

    return (
        <div style={internalDiv.mainContainerStyle}>
            <div style={divAlign}>
                <div style = {{marginBottom : "30px", height : "50px", display : "flex", justifyContent : 'center', alignItems: 'center'}}>
                    <IconOnaos />
                </div>
                <TitleFont20WithoutMarginBlueSso
                    text="Création de votre compte en cours"
                />
                {!first && !second && 

                    <Ring /> 
                }
                {first &&
                    <div style={{dispaly: "flex"}}>
                        <div style={circleStyle}>
                           <CheckCircleRoundedIcon />
                            <TitleFont20WithoutMarginBlueSso
                                text="Compte Crée"
                                style={circleStyle}
                            />
                        </div>
                        {second &&
                            <UnderTextSso
                                undertext="Redirection vers la page de connexion"
                                onClick={() => navigate("/connexion")}
                            />
                        }
                    </div>
                }
            </div>
        </div>
    )
}