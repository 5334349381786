import React, { useEffect, useState } from 'react';
import { InputSso, TitleFont20WithoutMarginBlueSso, ButtonSsoHover, IconOnaos, TextCenterBlue, UnderTextSso, TitleWithoutMargin } from 'onaosreact'
import { useSelector } from 'react-redux';
import { putAPI } from '../api/api';
import {  useNavigate } from 'react-router-dom';


const TAB = [
    {
        title: "E-mail",
        disabled: true,
        onChange: "",
        id: "email",
        errors: {
            dutyType: false,
            is: false,
        },
    },
    {
        title: "Nouveau mot de passe",
        placeholder: "Entrer votre nouveau mot de passe",
        onChange: "",
        id: "password",
        password: true,
        errors: {
            is: false,
            msg: "Veuillez entrer votre nouveau mot de passe"
        },
    },
    {
        title: "Confirmer mot de passe",
        placeholder: "Confirmer votre nouveau mot de passe",
        onChange: "",
        id: "passwordconfirm",
        password: true,
        errors: {
            is: false,
            msg: "Veuillez entrer votre nouveau mot de passe"
        },
    },
]

export const ChangePasswordByMail = (props) => {

    const connexion = useSelector((state) => state.token)
    const [, updateState] = useState();
    const [datas, setDatas] = useState({ email: connexion.email, password: "", passwordconfirm: "" });
    const [showMsg, setMsg] = useState(false)
    const navigate = useNavigate();

    const saveName = (id, value) => {
        const tmp = { ...datas }
        tmp[id] = value
        setDatas(tmp)
    }

    const divStyle = {
        padding: "0 10px",
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        flexDirection:'column'
    }

    const divPosition = {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left'
    }

    const checkDatas = () => {

        let isError = false;

        for (let index = 0; index < TAB.length; index++) {
            TAB[index].errors.is = datas[TAB[index].id] === "" ? true : false
            if (TAB[index].errors.is === true) {
                isError = true
                continue
            }

            if (TAB[index].id === "password" && datas.password !== datas.passwordconfirm) {
                TAB[index].errors.is = true
                TAB[index].errors.msg = "Les mots de passe ne correspondent pas"
            }

            if (TAB[index].id === "passwordconfirm" && datas.password !== datas.passwordconfirm) {
                TAB[index].errors.is = true
                TAB[index].errors.msg = "Les mots de passe ne correspondent pas"
            }

            isError = !isError && TAB[index].errors.is ? true : isError
        }
        if (isError)
            updateState({})
        else {
            const dataSend = {
                pass : datas.password,
                token : connexion.id,
                email : connexion.email

            }
            putAPI("/modifpass","", dataSend).then(data => {
                if (data.ok) {
                    setMsg(true)

                } else {
                    setMsg(data)
                    updateState({})
                }
            })
        }
    }

    useEffect(()=>{
        if (showMsg === true) {
            const search = window.location.search
            const params = new URLSearchParams(search)
            const r = params.get('f') === null ? 'dashboard' : params.get('f')

            const printSecond = setTimeout(() => {
                navigate("/connexion?r=" + r)
             }, 1500);
            return () => clearTimeout(printSecond)
        }
    }, [showMsg])

    return (
        <div style={divStyle}>
        <div style = {{marginBottom : "30px", height : "50px", display : "flex", justifyContent : 'center', alignItems: 'center'}}>
            <IconOnaos />
        </div>
        <div style={divPosition}>
            <TitleWithoutMargin
                text="Changement de mot de passe"
                style={{ fontSize: "20px", color: "#1A385E" }}
            />

                <div>
                {TAB.map((data, k) =>
                    <InputSso
                        key={"inputssso_" + k}
                        title={data.title}
                        password={data.password}
                        placeholder={data.placeholder !== undefined ? data.placeholder : datas[data.id]}
                        onChange={data.onChange !== undefined ? (e) => saveName(data.id, e) : ""}
                        data={data}
                        errors={data.errors}
                        disabled={data.disabled}
                        style = {{width: "100%"}}
                    />
                )}
                {showMsg !== true && 
                    <div style={{ marginTop: "30px" }}>
                        <ButtonSsoHover
                            text="Valider"
                            onClick={() => checkDatas()}
                        />
                    </div>
                }
                </div>
            </div>
            {showMsg === true &&
                <div>
                    <TextCenterBlue 
                        text = "Mot de passe changé avec succès, vous allez être redirigé vers la page de connexion"
                        style = {{color  :'rgb(103, 153, 30)', fontWeight : 'bold'}}
                    />
                    <UnderTextSso
                        undertext="Retour à la page de connexion"
                        onClick={() => navigate("/connexion")} /* TODO : use a var to redirect with service where reset was ask */
                    />
                </div>
            }
            {showMsg !== true && showMsg !== false && 
            <div>
                <TextCenterBlue 
                    text = {"Une erreur est survenue, merci de contacter le service client (erreur : "+showMsg+")"}
                    style = {{color  :'rgb(153, 30, 30)', fontWeight : 'bold'}}
                />
            </div>
            }
        </div>
    )
}