import React, { useEffect, useState } from 'react'

import { Route, Routes, useNavigate } from 'react-router-dom'
import './App.css'
import { CreateAccountSuccess } from './createAccount/CreateAccountSucces'
import { Connexion } from './identification/connexion/Connexion'

import { ForgetPassword } from './identification/forgetPassword/ForgetPassword'
import { Redirection, RedirectionPassword } from './redirection/Redirection'

export const App = () => {
    const [getParams, setGet] = useState({ r: 'dashboard', id: undefined, recup: undefined })
    const navigate = useNavigate()

    useEffect(() => {
        // 👇️ set style on body element
        document.body.style.backgroundColor = '#f7f7f7'

        return () => {
            document.body.style.backgroundColor = null
        }
    }, [])

    useEffect(() => {
        const search = window.location.search
        const params = new URLSearchParams(search)
        const r = params.get('r') === null ? 'dashboard' : params.get('r')
        const id = params.get('id')
        const recup = params.get('recup')
        if (r !== getParams.r || id !== getParams.id || recup !== getParams.recup) setGet({ r: r, id: id, recup: recup })
    })

    const goto = () => {
        navigate('/connexion')
    }

    return (
        <div className='App' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Routes>
                {getParams.recup !== null && getParams.recup !== undefined && (
                    <Route path='/connexion' element={<RedirectionPassword change={getParams.recup} goto={goto} />} />
                )}
                {/*id === null && <Route path="*" element={<Connexion service={r} />} />*/}
                {
                    getParams.id !== null && getParams.id !== undefined && (
                        <Route path='/connexion' element={<Redirection id={getParams.id} goto={goto} />} />
                    ) //create account
                }

                <Route exact path='/connexion/oubli' element={<ForgetPassword />} />
                {/* <Route exact path="/connexion/createaccountsucces" element={<CreateAccountSuccess goto = {goto}/>} /> */}

                {getParams.id === null && getParams.recup === null && <Route path='/connexion' element={<Connexion service={getParams.r} />} />}

                {getParams.id === null && getParams.recup === null && <Route path='*' element={<Connexion service={getParams.r} />} />}
            </Routes>
        </div>
    )
}
