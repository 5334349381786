import React, { useState } from 'react';
import { ButtonSsoHover, IconOnaos, InputSso, TextCenterBlue, TitleWithoutMargin } from 'onaosreact'
import { useSelector } from 'react-redux';
import { postAPI } from '../api/api';
import { useNavigate } from 'react-router-dom';
import { CreateAccountSuccess } from './CreateAccountSucces';

const TAB = [
    {
        title: "Nom",
        placeholder: "Entrer votre nom",
        onChange: "",
        id: "nom",
        errors: {
            dutyType: true,
            is: false,
            msg: "Veuillez entrer votre nom"
        },
    },
    {
        title: "Prénom",
        placeholder: "Entrer votre prénom",
        onChange: "",
        id: "prenom",
        errors: {
            dutyType: true,
            is: false,
            msg: "Veuillez entrer votre prénom"
        },
    },
    {
        title: "E-mail",
        disabled: true,
        onChange: "",
        id: "email",
        errors: {
            dutyType: false,
            is: false,
        },
    },
    {
        title: "Téléphone",
        placeholder: "Entrer votre numéro de téléphone",
        onChange: "",
        id: "telephone",
        errors: {
            dutyType: false,
            is: false,
            msg: "Le format de numéro n'est pas correct (ex : 0606060606)"
        },
    },
    {
        title: "Mot de passe",
        placeholder: "Entrer votre mot de passe",
        onChange: "",
        password: true,
        id: "password",
        errors: {
            dutyType: true,
            is: false,
            msg: "Veuillez entrer votre mot de passe",
        },
    },
    {
        title: "Confirmer votre mot de passe",
        placeholder: "Confirmer votre mot de passe",
        onChange: "",
        password: true,
        id: "passwordconfirm",
        errors: {
            dutyType: true,
            is: false,
            msg: "Veuillez entrer votre mot de passe",
        },
    },
]

export const CreateAccount = (props) => {

    const connexion = useSelector((state) => state.token)
    const [datas, setDatas] = useState({ nom: "", prenom: "", email: connexion.email, telephone: "", password: "", passwordconfirm: "" });
    const [, updateState] = useState();
    const [isTrue, setTRue] = useState(false);
    const [isError, setError] = useState(false);


    const saveName = (id, value) => {
        const tmp = { ...datas }
        tmp[id] = value
        setDatas(tmp)
    }

    const checkDatas = () => {

        let isError = false;

        for (let index = 0; index < TAB.length; index++) {
            TAB[index].errors.is = TAB[index].errors.dutyType === true && datas[TAB[index].id] === "" ? true : false

            if (TAB[index].errors.is === true) {
                isError = true
                continue
            }

            TAB[index].errors.is = TAB[index].id === "telephone" && datas.telephone !== "" && (datas.telephone.length !== 10 || isNaN(datas.telephone)) ? true : false

            if (TAB[index].errors.is === true) {
                isError = true
                continue
            }

            if (TAB[index].id === "password" && datas.password !== datas.passwordconfirm) {
                TAB[index].errors.is = true
                TAB[index].errors.msg = "Les mots de passe ne correspondent pas"
            }

            if (TAB[index].id === "passwordconfirm" && datas.password !== datas.passwordconfirm) {
                TAB[index].errors.is = true
                TAB[index].errors.msg = "Les mots de passe ne correspondent pas"
            }

            isError = !isError && TAB[index].errors.is ? true : isError
        }
        if (isError)
            updateState({})
        else {
            sendToApi()
            updateState({})
        }
    }

    const sendToApi = () => {
        updateState({})
        datas["id"] = connexion.id
        postAPI("/accountcreation", "", datas).then(data => {
            if (data.ok) {
                setTRue(true) 
            } else {
                setError(true)
            } 
        })
    }



    const divStyle = {
        padding: "0 10px",
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        flexDirection:'column'
    }

    const divPosition = {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left'
    }

    return (
        <React.Fragment>
            {isTrue &&
                <CreateAccountSuccess />
            }
            {!isTrue && !isError &&

                <div style={divStyle}>
                    <div style = {{marginBottom : "30px", height : "50px", display : "flex", justifyContent : 'center', alignItems: 'center'}}>
                        <IconOnaos />
                    </div>
                    <div style={divPosition}>
                        <TitleWithoutMargin
                            text="Créer votre compte"
                            style={{ fontSize: "20px", color: "#1A385E" }}
                        />
                        <div>
                            {TAB.map((data, k) =>
                                <InputSso
                                    style = {{width: "100%"}}
                                    key={"inputssso_" + k}
                                    title={data.title}
                                    password={data.password}
                                    placeholder={data.placeholder !== undefined ? data.placeholder : datas[data.id]}
                                    onChange={data.onChange !== undefined ? (e) => saveName(data.id, e) : ""}
                                    data={data}
                                    disabled={data.disabled}
                                    errors={data.errors}
                                />
                            )}
                            <div style={{ marginTop: "30px" }}>
                                <ButtonSsoHover
                                    text="Valider"
                                    onClick={() => checkDatas()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {isError && 
                <TextCenterBlue 
                    text = {"Une erreur est survenue, merci de contacter le service client."}
                    style = {{color  :'rgb(153, 30, 30)', fontWeight : 'bold'}}
                />
            }

        </React.Fragment>
    )
}